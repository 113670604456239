






































import { Component, Vue } from 'vue-property-decorator';
import AllFurnishedIcon from '@/assets/icons/furnished/all-furnished-icon.svg';
import NoFurnishedIcon from '@/assets/icons/furnished/no-furnished-icon.svg';
import SomeFurnishedIcon from '@/assets/icons/furnished/some-furnished-icon.svg';
import {createHelpers} from 'vuex-map-fields';
import { FurnishedEnum } from '@/interfaces';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import '@/validators/vee-validate';
import {IUser} from '@/services/api/user.service';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    AllFurnishedIcon,
    NoFurnishedIcon,
    SomeFurnishedIcon
  },
  computed: { ...mapFields(['user']) },
})
export default class TabFurnished extends Vue {
  private user!: IUser;
  private loading: boolean = false;

  private get listItem() {
    return [
      {
        value: FurnishedEnum.FULL,
        icon: AllFurnishedIcon,
        title: this.$tc('tabs.furnishedType.type.full')
      },
      {
        value: FurnishedEnum.NOT,
        icon: NoFurnishedIcon,
        title: this.$tc('tabs.furnishedType.type.no')
      },
      {
        value: FurnishedEnum.SEMI,
        icon: SomeFurnishedIcon,
        title: this.$tc('tabs.furnishedType.type.some')
      },
    ];
  }

  private onSubmit(): void {
    this.loading = true;
    this.$store.dispatch('saveUserFurnished')
      .then(() => this.$emit('nextStep'))
      .finally(() => this.loading = false);
  }
}
