






































import { Component, Vue } from 'vue-property-decorator';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import '@/validators/vee-validate';
import {createHelpers} from 'vuex-map-fields';
import YesIcon from '@/assets/icons/yes-icon.svg';
import NoIcon from '@/assets/icons/no-icon.svg';
import {IUser} from '@/services/api/user.service';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    YesIcon,
    NoIcon
  },
  computed: { ...mapFields(['user']) },
})
export default class TabDepositOffer extends Vue {
  private user!: IUser;
  private loading: boolean = false;

  private get listItem() {
    return [
      {
        value: true,
        icon: YesIcon,
        title: this.$tc('tabs.depositOffer.yes')
      },
      {
        value: false,
        icon: NoIcon,
        title: this.$tc('tabs.depositOffer.no')
      },
    ];
  }

  private onSubmit(): void {
    this.loading = true;
    this.$store.dispatch('saveUserSecurityDeposit')
      .then(() => this.$emit('nextStep'))
      .finally(() => this.loading = false);
  }
}
