


































import { Component, Vue } from 'vue-property-decorator';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import '@/validators/vee-validate';
import { PortfolioSizeEnum } from '@/interfaces';
import {IUser} from '@/services/api/user.service';
import PortfolioIcon from '@/assets/icons/portfolio.svg';

import {createHelpers} from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    PortfolioIcon
  },
  computed: {...mapFields(['user'])},
})
export default class TabPortfolio extends Vue {
  private user!: IUser;
  private loading: boolean = false;

  private get listItem() {
    return [
      {
        title: this.$tc('tabs.portfolio.units.under100'),
        value: PortfolioSizeEnum.UNDER_100
      },
      {
        title: this.$tc('tabs.portfolio.units.100to1000'),
        value: PortfolioSizeEnum.UNDER_1000
      },
      {
        title: this.$tc('tabs.portfolio.units.1000to10000'),
        value: PortfolioSizeEnum.UNDER_10000
      },
      {
        title: this.$tc('tabs.portfolio.units.10000to20000'),
        value: PortfolioSizeEnum.UNDER_20000
      },
      {
        title: this.$tc('tabs.portfolio.units.over20000'),
        value: PortfolioSizeEnum.OVER_20000
      }
    ];
  }

  private onSubmit(): void {
    this.loading = true;
    this.$store.dispatch('saveUserPortfolio')
      .then(() => this.$emit('nextStep'))
      .finally(() => this.loading = false);
  }
}
