




























































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ServerValidation from '@/mixins/serverValidation.mixin';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate';
import {createHelpers} from 'vuex-map-fields';
import EmailIcon from '@/assets/icons/mail.svg';
import {IUser} from '@/services/api/user.service';
import Address from '@/components/Address/Address.vue';
import {
  LandlordEnum,
} from '@/interfaces';

import {capitalize} from '@/utilities';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    EmailIcon,
    Address
  },
  computed: { ...mapFields(['user']) },
})
export default class TabLandlordDetails extends mixins(ServerValidation) {
  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>,
    searchStreetNumber: Vue
  };

  private user!: IUser;
  private isAddressValid: boolean = false;
  private isManualAddress: boolean = false;
  private addressErrors: string = '';
  private LandlordEnum = LandlordEnum;
  private loading: boolean = false;

  @Watch('$store.getters.currentStep')
  private tabChanged() {
    (this.$refs.observer as InstanceType<typeof ValidationObserver>).reset();
  }

  private get isCompanyNameRequired() {
    return !(
      [
        LandlordEnum.PRIVATE,
        LandlordEnum.INSTITUTIONAL
      ].includes(this.$store.getters.user.type)
    );
  }

  private onSubmit(): void {
    if (this.addressErrors) { return; }
    this.loading = true;

    this.$store.dispatch('saveUserDetails', this.isManualAddress)
      .then(() => this.$emit('nextStep'))
      .catch((error) => {
          this.setServerValidationErrors(
            this.$refs.observer,
            this.getServerErrors(error)
          );
          // @ts-ignore
          this.addressErrors = this.getServerErrors(error).address;
        }
      )
      .finally(() => this.loading = false);
  }

  private get disabled(): boolean {
    return this.isFieldsValid && this.isAddressValid;
  }

  private get isFieldsValid() {
    return !!(this.user.firstName && this.user.lastName && this.user.email
      && (this.user.type === this.LandlordEnum.PRIVATE ? true : this.user.companyName));
  }

  private set firstName(val) {
    this.user.firstName = capitalize(val);
  }

  private get firstName() {
    return this.user.firstName;
  }

  private set lastName(val) {
    this.user.lastName = capitalize(val);
  }

  private get lastName() {
    return this.user.lastName;
  }
}
