


























import { Component, Vue, Watch } from 'vue-property-decorator';
import {scrollTop} from '@/utilities';
import {VueConstructor} from 'vue';
import {StepEnum, ValueOf} from '@/interfaces';
import TokenService from '@/services/data/token.service';
import {IUser} from '@/services/api/user.service';

import IconPlaceholder from '@/assets/icons/intro-placeholder.svg';

import Step0 from './Tabs/Tab.landlordType.vue';
import Step1 from './Tabs/Tab.landlordDetails.vue';
import Step2 from './Tabs/Tab.propertyTypes.vue';
import Step3 from './Tabs/Tab.portfolio.vue';
import Step4 from './Tabs/Tab.leasingTools.vue';
import Step5 from './Tabs/Tab.depositOffer.vue';
import Step6 from './Tabs/Tab.furnished.vue';
import Step8 from './Tabs/Tab.agreement.vue';

import {createHelpers} from 'vuex-map-fields';
import {i18n} from '@/main';
const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.home.title'),
      meta: [{
        name: 'description',
        content: i18n.tc('meta.home.description')
      }]
    };
  },
  components: {
    IconPlaceholder,
  },
  computed: { ...mapFields(['user']) },
})
export default class HomePage extends Vue {
  private readonly stepDictionary: Partial<Record<StepEnum, VueConstructor>> = {
    [StepEnum.TYPE]: Step0,
    [StepEnum.DETAILS]: Step1,
    [StepEnum.PROPERTIES_TYPE]: Step2,
    [StepEnum.PORTFOLIO]: Step3,
    [StepEnum.LEASING_TOOL]: Step4,
    [StepEnum.SECURITY_DEPOSIT]: Step5,
    [StepEnum.FURNISHED]: Step6,
    [StepEnum.AGREEMENT]: Step8,
  };
  private readonly stepQueueList: Array<ValueOf<StepEnum>> = Object.keys(this.stepDictionary);
  private readonly stepLength: number = this.stepQueueList.length - 1;
  private user!: IUser;
  private isStepperAvailable: boolean = false;

  private get currentStep(): number {
    return this.$store.getters.currentStep;
  }

  @Watch('currentStep')
  private onCurrentStepChanged() {
    scrollTop();
  }

  private created(): void {
    this.$store.commit('SET_STEP_LENGTH', this.stepLength);

    if (this.$route.query.token) {
      this.$store.dispatch('setToken', this.$route.query.token);
    }

    if (TokenService.getToken()) {
      this.$store.dispatch('getUserByToken')
        .then(() => {
          if (this.stepQueueList.includes(this.user.lastCompletedPage!)) {
            if (this.user.lastCompletedPage === StepEnum.AGREEMENT) {
              this.$router.push({name: 'congratulation'});
              return;
            }

            this.jumpToStep(this.user.lastCompletedPage!);
          }
          this.isStepperAvailable = true;
        });
    } else {
      this.isStepperAvailable = true;
    }
  }

  private setStep(input: number): void {
    this.$store.dispatch('setStep', input);
  }

  private subtractStep(): void {
    this.$store.dispatch('subtractStep');
  }

  private prev(): void {
    this.subtractStep();
  }

  private next(): void {
    this.setStep(this.currentStep + 1);
    this.setToken();
  }

  private setToken(): void {
    if (!TokenService.getToken() && this.user.token) {
      this.$store.dispatch('setToken', this.user.token);
    }
  }

  private jumpToStep(name: ValueOf<StepEnum>): void {
    const savedLastStep: number = this.stepQueueList.indexOf(name);
    const nextStep = savedLastStep !== -1 ? savedLastStep + 1 : 0;
    this.setStep(nextStep);
    this.isStepperAvailable = true;
  }
}
