var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"text-h3 secondary--text mb-3",domProps:{"innerHTML":_vm._s(_vm.$t('tabs.landlordInfo.title'))}}),_c('div',{staticClass:"subtitle-1 secondary--text mb-4 mb-sm-7",domProps:{"innerHTML":_vm._s(_vm.$t('tabs.landlordInfo.subtitle'))}})]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"form-row mb-10"},[_c('div',{staticClass:"full d-flex justify-center flex-column"},[(_vm.user.type !== _vm.LandlordEnum.PRIVATE)?_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('tabs.landlordInfo.companyText')))]):_vm._e()]),(_vm.user.type !== _vm.LandlordEnum.PRIVATE)?_c('ValidationProvider',{staticClass:"full",attrs:{"rules":{required: _vm.isCompanyNameRequired}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"label":_vm.$t('tabs.landlordInfo.placeholders.companyName'),"error-messages":errors[0],"data-test-id":"company-name","maxlength":"255"},model:{value:(_vm.user.companyName),callback:function ($$v) {_vm.$set(_vm.user, "companyName", $$v)},expression:"user.companyName"}})]}}],null,true)}):_vm._e(),_c('div',{staticClass:"full d-flex justify-center flex-column"},[_c('Address',{attrs:{"address":_vm.user.address,"observer":_vm.$refs.observer},on:{"setAddressValid":function($event){_vm.isAddressValid = $event},"setManualAddress":function($event){_vm.isManualAddress = $event}}}),(_vm.user.type !== _vm.LandlordEnum.PRIVATE)?_c('div',{staticClass:"text-body-1 mt-5"},[_vm._v(_vm._s(_vm.$t('tabs.landlordInfo.contactText')))]):_vm._e()],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"label":_vm.$t('tabs.landlordInfo.placeholders.firstName'),"error-messages":errors[0],"data-test-id":"first-name","maxlength":"255"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"label":_vm.$t('tabs.landlordInfo.placeholders.lastName'),"error-messages":errors[0],"data-test-id":"last-name","maxlength":"255"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputPhone',{attrs:{"id":"profilePhone","placeholder":_vm.$t('tabs.landlordInfo.placeholders.number'),"error-messages":errors[0],"data-test-id":"landlord-phone"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var touched = ref.touched;
var changed = ref.changed;
return [_c('Input',{attrs:{"label":_vm.$t('tabs.landlordInfo.placeholders.email'),"error-messages":(invalid && touched || invalid && !changed) ? errors[0] : null,"data-test-id":"landlord-email","maxlength":"320"},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('EmailIcon',{staticClass:"icon"})]},proxy:true}],null,true),model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('Button',{attrs:{"disabled":!_vm.disabled,"loading":_vm.loading,"color":"primary","type":"submit","data-test-id":"continue-button"}},[_vm._v(_vm._s(_vm.$t('tabs.landlordInfo.button')))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }