






































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate';
import {PropertiesTypeEnum} from '@/interfaces';
import {IUser} from '@/services/api/user.service';

import ResidentialIcon from '@/assets/icons/propertyTypes/residential-icon.svg';
import CommercialIcon from '@/assets/icons/propertyTypes/commercial-icon.svg';
import BothIcon from '@/assets/icons/propertyTypes/both-icon.svg';

import {createHelpers} from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    ResidentialIcon,
    CommercialIcon,
    BothIcon
  },
  computed: { ...mapFields(['user']) },
})
export default class TabPropertyTypes extends Vue {
  private user!: IUser;
  private loading: boolean = false;

  private get listItem() {
    return [
      {
        value: PropertiesTypeEnum.RESIDENTIAL,
        icon: ResidentialIcon,
        title: this.$tc('tabs.propertyTypes.type.residential')
      },
      {
        value: PropertiesTypeEnum.COMMERCIAL,
        icon: CommercialIcon,
        title: this.$tc('tabs.propertyTypes.type.commercial')
      },
      {
        value: PropertiesTypeEnum.BOTH,
        icon: BothIcon,
        title: this.$tc('tabs.propertyTypes.type.both')
      },
    ];
  }

  private onSubmit(): void {
    this.loading = true;
    this.$store.dispatch('saveUserPropertyType')
      .then(() => this.$emit('nextStep'))
      .finally(() => this.loading = false);
  }
}
