




























import {Component, Vue} from 'vue-property-decorator';
import LottiePlayer from 'vue-lottie-player';
import {EnvProvider} from '@/utilities';
import {i18n} from '@/main';
import { getSiteUrl } from '@/utilities/getUrl';
import { getStatic } from '@/utilities/static';
import { defaultUser } from '@/store/modules/user.store';

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.congratulation.title'),
    };
  },
  components: { LottiePlayer }
})
export default class TabCongratulation extends Vue {
  public link: string = getSiteUrl(EnvProvider('URL_WEBSITE_LANDLORDS'));
  public getStatic = getStatic;

  private created(): void {
    const lifetime = localStorage.getItem('lifetime') && Number(localStorage.getItem('lifetime'));
    const now = new Date().getTime();
    if (!!lifetime && (now >= lifetime)) {
      this.resetSavedData();
      this.$router.push({name: 'index'});
    }
  }

  private resetSavedData() {
    localStorage.removeItem('lifetime');
    this.$store.dispatch('setToken', null);
    this.$store.commit('SET_USER', defaultUser());
  }
}
