


















































import { Component, Vue } from 'vue-property-decorator';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import '@/validators/vee-validate';
import {createHelpers} from 'vuex-map-fields';
import AgreementIcon from '@/assets/icons/reviewing-information-icon.svg';
import {IUser} from '@/services/api/user.service';
import DocumentLink from '@/components/Ui/DocumentLink.vue';
import {documentNamesEnum} from '@/interfaces';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    AgreementIcon,
    DocumentLink
  },
  computed: { ...mapFields(['user']) },
})
export default class TabAgreement extends Vue {
  public documentNamesEnum = documentNamesEnum;
  private user!: IUser;
  private isPartnerAgreement: boolean = false;
  private loading: boolean = false;

  private onSubmit() {
    if (!this.isPartnerAgreement) {
      return;
    }
    this.loading = true;
    this.$store.dispatch('saveUserAgreement')
      .then(() => this.$router.push({name: 'congratulation'}))
      .finally(() => this.loading = false);
  }
}
